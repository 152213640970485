@use "/src/sass/config";
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

:host,
app-page-head-spacer {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    z-index: 2;

    .square {
        width: 50%;
        position: absolute;
        top: -2rem;
        right: 0;
        height: 2rem;
        background: adm.theme-color("primary-dark");
    }
    .stage {
        overflow: hidden;
        > img,
        > picture {
            display: block;
            width: 100%;
        }
    }
}