@use "/src/sass/config";
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

app-section-image-parallax {
    display: block;

    &[data-container] {
        @include adm.breakpoint('desktop-hd') {
            border-radius: 1rem;
        }
        overflow: hidden;
    }
}

app-parallax-image {
    display: block;
    position: relative;
    z-index: 2;
    overflow: hidden;
    
    picture {
        width: 100%;
        display: block;

    }
    img {
        width: 100%;
        height: auto;
        display: block;
    }

    .inner {
        @include adm.container("large");
        position: absolute;
        top: adm.space();
        left: adm.edge-padding();
        right: adm.edge-padding();
        bottom: adm.space();
        z-index: 2;
        display: flex;
        align-items: flex-start;
        width: auto;

        h2 {
            @include adm.type("h1");
            opacity: 0.9;
        
            @include adm.breakpoint('tablet') {
                max-width: 50rem;
            }
        }

    }
}


