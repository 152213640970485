@use "/src/sass/config";
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

app-double-media,
:host {
    display: flex;
    gap: adm.space("smaller");
    flex-direction: column;
    position: relative;
    z-index: 2;

    @include adm.breakpoint("tablet") {
        flex-direction: row;
    }

    > app-parallax-image,
    > app-parallax-video {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 1rem;
    }


}
