@use "/src/sass/config";
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;
@use "/node_modules/swiper/swiper-vars.scss" as swiper-vars;
@use "/node_modules/swiper/swiper.scss";
@use "/node_modules/swiper/modules/pagination.scss";
@use "/node_modules/swiper/modules/scrollbar.scss";



:host,
app-x-slider {
    display: block;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: adm.edge-padding();
    padding-right: adm.edge-padding();
    padding-bottom: 4rem;

    --swiper-pagination-bullet-width: 2.8rem;
    --swiper-pagination-bullet-height: 0.6rem;
    --swiper-pagination-bullet-color: red;
    --swiper-pagination-color: #{adm.theme-color("accent")};
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-inactive-opacity: 0.6;
    
    --swiper-pagination-bullet-horizontal-gap: 0.6rem;
    
    .swiper-pagination-bullet {
        border-radius: 1rem;
    }


    .inner {
        width: 100%;
        max-width: adm.container("large");
    }


    .swiper {
        //width: 70%;
        overflow:visible;
        user-select: none;
        cursor: move;
    }


    .swiper-slide {
        height: auto;    
    }

    .swiper-pagination {
        bottom: -4rem !important;
    }
    .swiper-scrollbar {
        background: rgba(#fff, 0.2);
        bottom: -2rem !important;
    }
    .swiper-scrollbar-drag {
        background: rgba(#fff, 0.4);
    }

}

