/*##############################################
    Project Init
##############################################*/


/*----------------------------------------------
    Loading Adamantium UI
----------------------------------------------*/

@use "sass:map";
@use "config" as adm-cfg;                                                               // import the adamantium ui config

// core 
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/utils/normalize";   // just to bring everything in shape before we start...
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;       // init adamantium ui core and make it available in the adm namespace (sass modules)
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/root";              // load the core root to generate all css vars once (even with webcomponents, we only need it once!)

@use "generate";


// base styles
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/content/link/link" as adm-content-links;

// components
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/components/button/button" as adm-button;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/components/section/section" as adm-section;


//@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/components/dropdown/dropdown" as adm-dropdown;




/*----------------------------------------------
    Project specific Styles
----------------------------------------------*/

@use "/src/sass/components/double-media";
@use "/src/sass/components/page-head-spacer";
@use "/src/sass/components/parallax-image";
@use "/src/sass/components/x-slider";


 

/*----------------------------------------------
    Other Init Styles
----------------------------------------------*/

// https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Noto+Sans:wght@300;500;700&display=swap
// google fonts
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: optional;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwiPGQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: optional;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    font-display: optional;
    src: url(https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjThZVZNyB.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-display: optional;
    src: url(https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjFhdVZNyB.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: optional;
    src: url(https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjXhFVZNyB.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: optional;
    src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html {
    scroll-behavior: smooth;
  }


.menu-open {
    html, body {
        overflow: hidden;
    }
    body {
       
    }

    .app-main {
        padding-right: var(--scrollbar-size);
    }
    
}


.app-main {
    //background: yellow;
    min-height: calc(100vh + 12rem);


    &::before {
        content: '';
        display: block;
        width: 8rem;
        height: 8rem;
        
        position: fixed;
        top: calc(50% - 4rem);
        left: calc(50% - 4rem);
        z-index: -1;
        background-image: url('../static/assets/img/loading.gif');
        background-size: contain;
        pointer-events: none;
        opacity: 1;
        transition: 0.2s ease-in-out;

    }


}

.is-loaded .app-main::before {
    opacity: 0;
}


body {
	background: adm.theme-color("background");
	color: adm.theme-color("text");
    
    /*

    &::before {
        content: '';
        display: block;
        height: 50vw;
        width: 70vw;
        position: fixed;
        top: 0;
        right: 0;
        background: radial-gradient(circle at top right, adm.theme-color("primary") 0%, rgba(205,0,255,0) 60%);
        pointer-events: none;
        opacity: 0.4;
        z-index: 1;
        @include adm.breakpoint('tablet') {
            height: 50vw;
            width: 50vw;
        }
    }
    */

    &::before {
        content: '';
        will-change: transform;
        display: block;
        height: 150vw;
        width: 150vw;
        position: fixed;
        top: 0;
        left: 0;
        background: radial-gradient(circle, adm.theme-color('primary') 0%, rgba(0,0,0, 0) 60%);
        background-repeat: no-repeat;
        //background: green;
        pointer-events: none;
        opacity: 0.3;
        z-index: 1;
        transform: translate(calc(-50% + var(--mouse-x)), calc(-50% + var(--mouse-y)));
        transition: all 60ms linear;
    }


    &::after {
        content: '';
        display: block;
        height: 60vw;
        width: 80vw;
        position: fixed;
        bottom: 0;
        left: 0;
        background: radial-gradient(circle at bottom left, adm.theme-color("primary") 0%, rgba(205,0,255,0) 60%);
        pointer-events: none;
        opacity: 0.2;
        z-index: 1;

        @include adm.breakpoint('tablet') {
            height: 50vw;
            width: 40vw;
        }
    }
    
}


b {
    font-weight: 700;
}

p {
    margin: 0;
}


.adm-type-lead:first-child {
    margin-top: 0;
}

.adm-type-lead:only-of-type {
    margin-bottom: 0;
}

a {

    .adm-icon {
        color: adm.theme-color("accent");
        margin-right: 1rem;
    }
}

a.more {
    color: adm.theme-color("accent");
    font-weight: 700;
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    .adm-icon {
        color: inherit;
        transition: all 0.2s ease-in-out;
        font-size: 1.2em;
    }

    &:hover {
        text-decoration: underline solid adm.theme-color("accent") 3px;
        text-underline-offset: 10px;
        .adm-icon {
            transform: translateX(0.5rem);
        }
    }
}